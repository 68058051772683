import videoShaderFrag from '../shaders/videoShaderFrag.glsl';
import videoShaderVert from '../shaders/videoShaderVert.glsl';
import {
    AnimationClip,
    AnimationMixer,
    Audio,
    Camera,
    Clock,
    LinearFilter,
    LoopOnce,
    LoopRepeat,
    Mesh,
    MeshBasicMaterial,
    Object3D,
    Plane,
    PlaneGeometry,
    RGBAFormat,
    Scene,
    ShaderMaterial,
    Vector2,
    Vector3,
    VideoTexture,
} from 'three';
import {
    ImageTargetView,
    artworkLoadingAtom,
    imageTargetViewAtom,
    imageTargetAtom,
    imageTargetStore,
    imageTargetVideo,
    imageTargetAudio,
} from './8thwall/atoms';
import { ImageTargetPipelineModuleResult } from './8thwall/image-target-pipeline-module';
import {
    getArtworkTargetDimensions,
    scaleToCover,
    scaleToFit,
} from './utilities/utilities';
import { artworkData } from './artworks';
import { IArtwork } from './types';
import { I8thWallImageTargetEventModel } from './8thwall';
import ReactGA from 'react-ga4';
import { GLTF, GLTFLoader } from 'three/examples/jsm/Addons.js';
import gsap from 'gsap';

export function initImageTargetExperience(
    module: ImageTargetPipelineModuleResult,
    scene: Scene,
    camera: Camera,
    audio: Audio,
) {
    enum ArtworkState {
        LOADING,
        PLAYING,
        STOPPED,
    }
    type ITargetType = I8thWallImageTargetEventModel['detail'] & {
        distanceToScreenCenter?: number;
    };
    let artworks: IArtwork[] = [];
    artworks = artworkData;

    const targetsFound: ITargetType[] = [];
    const targetPosition3D = new Vector3();
    const targetPosition2D = new Vector2();
    const screenCenter = new Vector2(0.5, 0.5);
    let artworkActive: IArtwork | null;
    let artworkActiveLostTime = 0.0;
    const artworkActiveLostTimeMax = 6.0;
    let bResetVideoAnimation = false;
    const bBlockVideoAnimation = false;

    let pauseRender = true;
    let loadProgress = 0;

    const clock = new Clock();

    const loader = new GLTFLoader(); // This comes from GLTFLoader.js.
    let mixer: AnimationMixer;
    let clips: AnimationClip[];
    let deltaSeconds = 0;
    let needsFading = true;

    const tunnelZThreshold = {
        value: 1,
        // value: 0.1 + 0.085704,
    };
    const clippingPlane = new Plane(new Vector3(0, 0, -1), 0);

    const video = imageTargetStore.get(imageTargetVideo);
    // Add event listener to notify when video ends
    video.addEventListener('ended', () => {
        video.play();
    });
    const texture = new VideoTexture(video);
    texture.minFilter = LinearFilter;
    texture.magFilter = LinearFilter;
    texture.format = RGBAFormat;

    const uniforms = {
        videoTexture: { type: 't', value: texture },
        videoHasAlpha: { value: false },
        videoLoaded: { value: false },
        timeElapsed: { value: 0.0 },
        timeMark: { value: 0.0 },
    };

    const shaderMaterial = new ShaderMaterial({
        uniforms: uniforms,
        vertexShader: videoShaderVert,
        fragmentShader: videoShaderFrag,
        transparent: true,
    });

    const videoPlane = new PlaneGeometry(1, 1);
    const videoMesh = new Mesh(videoPlane, shaderMaterial);
    const videoContainer = new Object3D();
    videoContainer.visible = false;
    // videoContainer.add(videoMesh);

    scene.add(videoContainer);

    imageTargetStore.set(imageTargetViewAtom, ImageTargetView.SCANNING);

    // link audio
    const audioElement = imageTargetStore.get(imageTargetAudio);

    audio.setMediaElementSource(audioElement);
    audio.hasPlaybackControl = true;

    const artworkStop = () => {
        video.pause();
        videoMesh.material.uniforms.videoLoaded.value = false;
        (videoMesh.material.uniforms.needsUpdate as unknown as boolean) = true;
    };

    const modelStop = () => {
        // fadeOutModel()
        if (mixer && clips) {
            clips.forEach(function (clip) {
                mixer.clipAction(clip).stop();
            });
        }
        materials.forEach((material: any) => {
            material.opacity = 0;
        })
        
        if (audioElement) {
            audioElement.pause();
            audioElement.currentTime = 0;
        }
    };

    const artworkPlay = async (artwork: IArtwork) => {
        const { width: targetWidth, height: targetHeight } =
            await getArtworkTargetDimensions(artwork);

        video.src =
            artwork.files.find((file) => file.type === 'animation')?.path ?? '';

        video.load();
        video.oncanplay = () => {
            video.play();

            // Scale eyejack image target within 4:3 ratio (8thwall image target format)
            const targetDims = scaleToFit(
                new Vector2(targetWidth, targetHeight).normalize(),
                new Vector2(0.75, 1),
            );

            const { videoWidth, videoHeight } = video;
            // Scale video size to cover target
            const videoDims = scaleToCover(
                new Vector2(
                    artwork.animationHasAlpha ? videoWidth / 2 : videoWidth,
                    videoHeight,
                ).normalize(),
                targetDims,
            );

            videoMesh.scale.x = videoDims.x;
            videoMesh.scale.y = videoDims.y;

            videoMesh.material.uniforms.videoHasAlpha.value =
                artwork.animationHasAlpha;
            videoMesh.material.uniforms.videoLoaded.value = true;
            (videoMesh.material.uniforms.needsUpdate as unknown as boolean) =
                true;

            loadProgress < 100
                ? imageTargetStore.set(
                      imageTargetViewAtom,
                      ImageTargetView.LOADING,
                  )
                : imageTargetStore.set(
                      imageTargetViewAtom,
                      ImageTargetView.VIEWING,
                  );

            setTimeout(() => {
                imageTargetStore.set(artworkLoadingAtom, false);
            }, 500);
        };

        videoMesh.scale.x = 1.0;
        videoMesh.scale.y = 1.0;
        videoMesh.material.uniforms.timeMark.value = clock.getElapsedTime();
        (videoMesh.material.uniforms.needsUpdate as unknown as boolean) = true;

        imageTargetStore.set(artworkLoadingAtom, true);
    };
    const modelPlay = () => {
        // console.log('vide container', videoContainer);
        fadeInModel();
        if (loadProgress < 100) {
            imageTargetStore.set(imageTargetViewAtom, ImageTargetView.LOADING);
            return;
        } else {
            imageTargetStore.set(imageTargetViewAtom, ImageTargetView.VIEWING);
        }

        if (mixer && clips) {
            clips.forEach(function (clip) {
                mixer.clipAction(clip).reset().play();
                mixer.clipAction(clip).clampWhenFinished = true;
                mixer.clipAction(clip).setLoop(LoopOnce);
            });
        }

        if (audioElement) {
            audioElement.play();
        }
        needsFading = true;

    };

    const materials: any = [];
    const fadeInModel = () => {
        gsap.to(materials, {
            opacity: 1,
            duration: 2,
            ease: 'power2.inOut',
            onComplete: () => {
                // console.log('fade in complete');
            },
        });
    };

    const fadeOutModel = () => {
        gsap.to(materials, {
            opacity: 0,
            duration: 2,
            ease: 'power2.inOut',
            onComplete: () => {
                // console.log('fade out complete');
                gsap.delayedCall(5, () => modelPlay());
            },
        });
    };

    const artworkLoader = (path: string) => {
        return new Promise<GLTF>((resolve) => {
            loader.load(
                path,
                (gltf) => {
                    resolve(gltf);
                },
                (progress) => {
                    loadProgress = (progress.loaded / progress.total) * 100;
                    // console.log(loadProgress, progress.total)
                    // console.log(Math.floor((progress.loaded / progress.total) * 100) + '% loaded');
                    module.emitter.emit(
                        'content-load-progress',
                        Math.floor((progress.loaded / progress.total) * 100),
                    );

                    if ((progress.loaded / progress.total) * 100 === 100)
                        module.emitter.emit('content-loaded');
                },
                (error) => {
                    console.log('An error occured loading model: ', error);
                },
            );
        });
    };
    const loadArtwork = async (path: string, audioPath?: string) => {
        const gltf = await artworkLoader(path);
        const model = gltf.scene;

        // precise alignment to image target
        model.scale.set(1.025, 1.055, 1);
        model.position.y += 0.03;

        // move back to match position of mask
        model.position.z -= 0.085704;

        // asign clipping planes and masking to correct elements
        model.traverse((element) => {
            const parentName = element.parent?.name;
            const isMesh = (element as Mesh).isMesh;
            const name = element.name;
            if (parentName === 'Tunnel_Mover' && name !== 'Sphere') {
                element.traverse((child) => {
                    if ((child as Mesh).isMesh) {
                        const meshChild = child as Mesh;
                        if (
                            (meshChild.material as MeshBasicMaterial)
                                .clippingPlanes !== undefined
                        ) {
                            (
                                meshChild.material as MeshBasicMaterial & {
                                    clippingPlanes: Plane[];
                                }
                            ).clippingPlanes = [clippingPlane];
                        }
                    }
                });
            }
            if (isMesh) {
                const meshElement = element as Mesh;
                materials.push(meshElement.material);
                meshElement.material.transparent = true;
                meshElement.material.opacity = 0; // Start with invisible model
                if (name.includes('Mask')) {
                    if (
                        (meshElement.material as MeshBasicMaterial)
                            .colorWrite !== undefined
                    ) {
                        (
                            meshElement.material as MeshBasicMaterial & {
                                colorWrite: boolean;
                            }
                        ).colorWrite = false;
                    }
                    meshElement.frustumCulled = false;
                    meshElement.renderOrder = 0;
                    // prevents z-fighting
                    meshElement.position.z += 0.005;
                } else {
                    meshElement.renderOrder = 1;
                }
            }
        });

        // console.log('gltf', gltf);
        videoContainer.add(model);
        model.visible = true;
        mixer = new AnimationMixer(model);
        clips = gltf.animations;
        clips.forEach(function (clip) {
            mixer.clipAction(clip).loop = LoopRepeat;
        });

        mixer.addEventListener('finished', function () {
            if (needsFading) {
                // console.log('finished mixer');
                needsFading = false;
                fadeOutModel();
            }
        }); // properties of e: type, action and direction

        if (audioPath) {
            // const audioLoader = new AudioLoader();
            // audioLoader.load(audioPath, (buffer) => {
            //     audio.setBuffer(buffer);
            //     audio.setVolume(0.8);
            //     audio.setLoop(true);
            //     audio.name = 'content audio'
            // });
            // audioElement.pause()
            audioElement.src = audioPath;
            audioElement.pause();
        }

        if (
            imageTargetStore.get(imageTargetViewAtom) ===
            ImageTargetView.LOADING
        ) {
            modelPlay();
        }
    };

    module.emitter.on('resume-tracking', () => (pauseRender = false));
    module.emitter.on('pause-tracking', () => (pauseRender = true));

    module.emitter.on('on-update-scene', () => {
        deltaSeconds = clock.getDelta();
        if (mixer) mixer.update(deltaSeconds);
        if (pauseRender) {
            if (artworkActive) {
                // artworkStop();
                modelStop();
                artworkActive = null;
            }
            return;
        }
        for (let i = 0; i < targetsFound.length; i++) {
            const target = targetsFound[i];
            targetPosition3D.copy(target.position);
            targetPosition3D.project(camera);
            targetPosition2D.x = (targetPosition3D.x + 1) / 2;
            targetPosition2D.y = (-targetPosition3D.y + 1) / 2;
            target.distanceToScreenCenter =
                targetPosition2D.distanceTo(screenCenter);
        }

        targetsFound.sort((a, b) => {
            return a.distanceToScreenCenter && b.distanceToScreenCenter
                ? a.distanceToScreenCenter - b.distanceToScreenCenter
                : 0;
        });

        let targetFound = null;
        let artworkFound = null; // the artwork that is meant to be playing right now (can also be null if no artwork is found)
        if (artworkActive) {
            for (let i = 0; i < targetsFound.length; i++) {
                if (artworkActive.targetName === targetsFound[i].name) {
                    artworkFound = artworkActive;
                    targetFound = targetsFound[i];
                    break;
                }
            }
        }
        if (!artworkFound) {
            if (targetsFound.length > 0) {
                const targetFirst = targetsFound[0];
                for (let i = 0; i < artworks.length; i++) {
                    if (artworks[i].targetName === targetFirst.name) {
                        artworkFound = artworks[i];
                        targetFound = targetFirst;
                        break;
                    }
                }
            }
        }

        if (artworkActive != null) {
            if (artworkFound != null) {
                if (artworkActive.targetName == artworkFound.targetName) {
                    artworkActiveLostTime = 0.0;
                }
            } else {
                artworkActiveLostTime += deltaSeconds;
            }
        }

        let bReplace = false;
        if (artworkFound != null && artworkActive != null) {
            bReplace = artworkActive.targetName != artworkFound.targetName;
        }

        let bTimeout = false;
        if (artworkActiveLostTime >= artworkActiveLostTimeMax) {
            artworkActiveLostTime = artworkActiveLostTimeMax;
            bTimeout = true;
            imageTargetStore.set(imageTargetViewAtom, ImageTargetView.SCANNING);
        }

        let bRestart = false;
        bRestart = bRestart || bResetVideoAnimation;
        bRestart = bRestart || bBlockVideoAnimation;
        bResetVideoAnimation = false;
        const bStop =
            artworkActive != null && (bReplace || bTimeout || bRestart);
        if (bStop) {
            // artworkStop();
            modelStop();
            artworkActive = null;
        }

        let bPlay = true;
        bPlay = bPlay && artworkFound != null;
        bPlay = bPlay && artworkActive == null;
        bPlay = bPlay && bBlockVideoAnimation == false;
        if (bPlay) {
            artworkActive = artworkFound;
            artworkActiveLostTime = 0.0;
            if (!artworkActive) return;
            imageTargetStore.set(imageTargetAtom, artworkActive);
            ReactGA.event('artwork_viewed', {
                artwork_viewed: artworkActive.artist,
            });
            // artworkPlay(artworkActive);
            modelPlay();
        }

        let bShowVideo = true;
        bShowVideo = bShowVideo && artworkActive != null;
        bShowVideo = bShowVideo && targetFound != null;
        if (bShowVideo) {
            if (!targetFound) return;
            videoContainer.position.set(
                targetFound.position.x,
                targetFound.position.y,
                targetFound.position.z,
            );
            videoContainer.quaternion.copy(
                targetFound.rotation as THREE.Quaternion,
            );
            videoContainer.scale.set(
                targetFound.scale,
                targetFound.scale,
                targetFound.scale,
            );

            videoContainer.visible = true;
        } else {
            videoContainer.visible = false;
        }
        videoMesh.material.uniforms.timeElapsed.value = clock.getElapsedTime();
        (videoMesh.material.uniforms.needsUpdate as unknown as boolean) = true;

        const bFade = false; // does not work - think it also needs to be hooked up to a button event.
        if (bFade) {
            if (video != null) {
                let volume =
                    1.0 - artworkActiveLostTime / artworkActiveLostTimeMax;
                volume = Math.floor(volume * 10) / 10.0;
                if (video.volume != volume) {
                    video.volume = volume;
                }
            }
        }
    });

    module.emitter.on('on-show-target', ({ detail }) => {
        targetsFound.push(detail);
    });

    function createDirectionFromQuaternion(object: Object3D) {
        const quaternion = object.quaternion.clone();
        const defaultDirection = new Vector3(0, 0, -1);
        const direction = defaultDirection
            .applyQuaternion(quaternion)
            .normalize();
        return direction;
    }

    module.emitter.on('on-update-target', ({ detail }) => {
        for (let i = 0; i < targetsFound.length; i++) {
            if (targetsFound[i].name == detail.name) {
                targetsFound[i] = detail;
                break;
            }
        }
        const distance = videoContainer.position.distanceTo(camera.position);
        const dir = createDirectionFromQuaternion(videoContainer);
        clippingPlane.normal.set(dir.x, dir.y, dir.z);
        clippingPlane.constant = -distance - tunnelZThreshold.value;
    });

    module.emitter.on('on-hide-target', ({ detail }) => {
        for (let i = 0; i < targetsFound.length; i++) {
            if (targetsFound[i].name == detail.name) {
                targetsFound.splice(i, 1);
                break;
            }
        }
    });

    return {
        loadArtwork,
    };
}
