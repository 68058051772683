import { useAtom } from 'jotai';
import { useEffect, useErrorBoundary, useMemo, useState } from 'preact/hooks';
import '../app.css';
import { ErrorBoundary } from '../components/ErrorBoundary';
import { FadeTransition } from '../components/Transitions';
import { InfoModal } from '../features/info-modal';
import MediaPreview from '../features/media-preview';
import { OnboardingModals } from '../features/onboarding';
import { RecordingButton, useVideoRecorder } from '../features/recording-button';
import Splash from '../features/splash';
import TrackingOverlay from '../features/tracking-overlay';
import useUserDevice from '../hooks/useUserDevice';
import { RendererApi, initExperienceRenderer } from '../renderer';
import {
    ImageTargetView,
    artworkLoadingAtom,
    imageTargetAtom,
    imageTargetViewAtom,
} from '../renderer/8thwall/atoms';

import InfoButtonImg from '../assets/default/info.svg';
import useScreenOrientation from '../hooks/useScreenOrientation';
import LandscapeOverlay from '../features/landscape-overlay';
import { createPortal } from 'preact/compat';
import InstructionOverlay from '../assets/instructions/instructions-overlay.svg';
import AudioFile from '/audio.mp3?url';
import WatermarkFile from '../assets/default/watermark.png';
import Spinner from '../components/Spinner';

const params = new URLSearchParams(window.location.search);
const arParam = params.get('noAR');
let renderAR = true;
if (arParam === '1') {
    renderAR = false;
}

export function App() {
    const [error, resetError] = useErrorBoundary();

    const [renderer, setRenderer] = useState<RendererApi>();
    const [isLoadingExperience, setIsLoadingExperience] = useState(false);

    const { device, isMobile } = useUserDevice();
    const screenOrientation = useScreenOrientation();

    const initExperience = async () => {
        setIsLoadingExperience(true);
        const canvasEl = document.getElementById(
            'xr-canvas',
        ) as HTMLCanvasElement;
        if (!canvasEl) throw new Error('No Canvas element.');
        const renderer = await initExperienceRenderer(canvasEl, {
            watermarkImageUrl: '',
        });
        setRenderer(renderer);
        renderer.loadContent('/TheNextDimension-FINAL.glb', AudioFile);
        console.log('load')
        await new Promise((resolve) => setTimeout(resolve, 500));
        setShowOnboardingModal(true);
        setIsLoadingExperience(false);
    };

    const resetErrors = () => {
        resetError();
        window.location.reload();
    };

    const [imageTarget, _setImageTarget] = useAtom(imageTargetAtom);
    const [imageTargetView, _setimageTargetView] = useAtom(imageTargetViewAtom);
    const [artworkLoading, _setArtworkLoading] = useAtom(artworkLoadingAtom);

    const [showOnboardingModal, setShowOnboardingModal] = useState(false);
    const [showInfoModal, setShowInfoModal] = useState(false);

    const [progress, setProgress] = useState(0);

    const handleOnboardingClose = async () => {
        setShowOnboardingModal(false);

        // setHasViewedOnboarding(true);
    };

    const handleInfoClose = () => {
        setShowInfoModal(false);
    };

    /**
     * Tracking state
     */
    const recordingState = useVideoRecorder(renderer);

    const showImageTrackingOverlay = useMemo(() => {
        return (
            recordingState.state === 'none' &&
            !showOnboardingModal &&
            imageTargetView === ImageTargetView.SCANNING &&
            !showInfoModal
        );
    }, [recordingState, showOnboardingModal, imageTargetView, showInfoModal]);

    const showRecording = useMemo(() => {
        return (
            recordingState.state !== 'ready' &&
            !showOnboardingModal &&
            !showImageTrackingOverlay &&
            !showInfoModal
        );
    }, [
        recordingState,
        showOnboardingModal,
        showImageTrackingOverlay,
        showInfoModal,
    ]);

    const showMediaModal = useMemo(() => {
        return recordingState.state === 'ready';
    }, [recordingState.state]);

    const onVideoCleared = () => {
        if (recordingState.state === 'ready') {
            recordingState.clear();
        }
        _setimageTargetView(ImageTargetView.SCANNING);
    };

    /**
     * Integrate renderer events with react state.
     */

    // useEffect(() => {
    //     if (!renderer) return;
    //     const handleLoadProgress = (progress: number) => {
    //         setProgress(progress);
    //     };

    //     renderer.on('content-load-progress', handleLoadProgress);
    //     return () => {
    //         renderer.off('content-load-progress', handleLoadProgress);
    //     };
    // }, [renderer]);

    const shouldRendererPause = useMemo(() => {
        return (
            showOnboardingModal ||
            recordingState.state === 'ready' ||
            (screenOrientation?.includes('landscape') && isMobile)
        );
    }, [showOnboardingModal, , recordingState, screenOrientation, isMobile]);

    useEffect(() => {
        if (!renderer) return;
        shouldRendererPause
            ? renderer.pauseTracking()
            : renderer.resumeTracking();
    }, [shouldRendererPause, renderer]);

    useEffect(() => {
        if (!renderAR) {
            initExperience();
        }
    }, []);
    /**
     * JSX
     */
    if (error) {
        return (
            <ErrorBoundary error={error.message} onResetError={resetErrors} />
        );
    }

    return (
        <>
            {createPortal(
                <FadeTransition
                    show={screenOrientation?.includes('landscape') && isMobile}
                >
                    <LandscapeOverlay />
                </FadeTransition>,
                document.body,
            )}

            <FadeTransition show={renderer && !isLoadingExperience}>
                <div className="flex items-center border-ra">
                    <FadeTransition show={showOnboardingModal && renderAR}>
                        <OnboardingModals onClose={handleOnboardingClose} />
                    </FadeTransition>
                    <FadeTransition show={showImageTrackingOverlay}>
                        <TrackingOverlay>
                            <div className="w-full relative h-full z-[999] ">
                                <img
                                    src={InstructionOverlay}
                                    className="max-w-[222px] w-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                                />
                                <h2 className="absolute xuppercase bottom-14 left-1/2 -translate-x-1/2 px-8 max-w-[350px] w-full text-center text-2xl tracking-[1px] leading-[30px] text-shadow-base">
                                    Point your device at
                                    the book cover
                                </h2>
                            </div>
                        </TrackingOverlay>
                    </FadeTransition>

                    <FadeTransition show={showInfoModal}>
                        <InfoModal onClose={handleInfoClose} />
                    </FadeTransition>
                    {/* {createPortal(
                        document.body,
                    )} */}
                    <FadeTransition show={showRecording}>
                        <RecordingButton
                            recordingState={recordingState}
                            renderer={renderer!}
                        />
                    </FadeTransition>
                    {createPortal(
                        <FadeTransition show={showMediaModal}>
                            <MediaPreview
                                recordingState={recordingState}
                                onVideoCleared={onVideoCleared}
                            />
                        </FadeTransition>,
                        document.body,
                    )}
                    <FadeTransition
                        show={
                            (!showOnboardingModal &&
                                !showInfoModal &&
                                recordingState.state === 'none' &&
                                imageTargetView === ImageTargetView.LOADING)
                        }
                    >
                        <div className="bg-[rgba(0,0,0,0.7)] w-full h-full absolute z-20 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                            <div className="flex items-center justify-center flex-col h-full gap-3">
                                <Spinner />
                                <div className="flex w-full items-center justify-center">

                                    {/* <span className="">{progress}%</span> */}
                                {/* <div className="max-w-[200px] w-full bg-gray-400 rounded-full h-2 flex">
                                    <div
                                        className="bg-white h-2 rounded-full "
                                        style={{ width: `${80}%` }}
                                    ></div>
                                </div> */}
                                </div>
                            </div>
                        </div>
                    </FadeTransition>
                    <FadeTransition
                        show={
                            !showOnboardingModal &&
                            !showInfoModal &&
                            recordingState.state === 'none' &&
                            imageTargetView !== ImageTargetView.VIEWING
                        }>
                        <button
                            className="absolute top-5 right-5 pointer-events-auto z-50"
                            onClick={() => {
                                setShowInfoModal(true);
                            }}
                        >
                            <img
                                className="relative z-50 w-[42px] h-[42px]"
                                src={InfoButtonImg}
                            />
                        </button>
                    </FadeTransition>
                </div>
            </FadeTransition>

            <FadeTransition
                show={(!renderer || isLoadingExperience) && renderAR}
            >
                <Splash
                    isLoadingExperience={isLoadingExperience}
                    device={device}
                    onPermissionsGranted={initExperience}
                />
            </FadeTransition>
        </>
    );
}
